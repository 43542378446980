














































































































































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'progress-bar-custom': () => import('@/modules/popo/components/progress-bar-custom.vue'),
  },
})
export default class extends Vue {
  popoStore = popoStore
  @Prop({ default: false }) isDecoratedPopoDetail
  @Prop({
    default: () => {
      return {}
    },
  })
  decoratedPopoInfo
}
